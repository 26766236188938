import React, { createContext, useContext, useEffect } from 'react'
import { message, notification } from 'antd'
import { ArgsProps, NotificationConfig } from 'antd/es/notification/interface'

export const NotificationContext = createContext(null)

type propsType = {
  type: 'success' | 'error' | 'error' | 'warning' | 'open' | 'destroy'
} & ArgsProps &
  NotificationConfig

const OverrideAntdNotification = ({ children }: any) => {
  const [api, contextHolder] = notification.useNotification()

  notification.config

  const openNotification = ({
    placement,
    description,
    message,
    type,
    ...rest
  }: propsType) => {
    api[type]({
      message,
      description,
      placement: placement ? placement : 'bottomLeft',
      ...rest,
    })
  }

  return (
    <NotificationContext.Provider
      value={{
        openNotification: openNotification,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  )
}

export default OverrideAntdNotification

export const useOverrideAntdNotification = () => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error('useMeContext must be used with a NotificationContext')
  }
  return context
}
