import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Breadcrumb } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import useRouteConfig from '@/routes'
import { useMeContext } from '@/providers/meProvider'

const handleMatchRouteList = (data, value) => {
  const result = []
  data.forEach(({ ...item }) => {
    if (item.permissions.includes(value)) {
      if (item.routes && Array.isArray(item.routes)) {
        item.routes = handleMatchRouteList(item.routes, value)
        if (!item.routes.length) {
          delete item.routes
        }
      }
      result.push(item)
    }
  })
  return result
}

export default function Breadcrumbs() {
  const location = useLocation()
  const routeConfig = useRouteConfig()
  const me = useMeContext()
  const [routeList, setRouteList] = useState([])
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const findMatchValueByPathName = (data, initialValue) => {
    const result = []
    const innerFunc = data => {
      data.forEach(({ ...item }) => {
        if (initialValue.includes(item.path)) {
          result.push(item)
        }
        if (item.routes && Array.isArray(item.routes)) {
          innerFunc(item.routes)
        }
      })
    }
    innerFunc(data)
    return result
  }
  useEffect(() => {
    if (me?.userInfo?.role?.value) {
      setRouteList(
        handleMatchRouteList([...routeConfig], me?.userInfo?.role.value),
      )
    }
  }, [me?.userInfo?.role?.value])

  useEffect(() => {
    const result = findMatchValueByPathName(routeList, location.pathname)
    if (result) {
      setBreadcrumbs(
        [...result].map(({ ...item }, idx, self) => {
          return {
            title:
              idx && idx < self.length - 1 ? (
                <Link to={item.path}>{item.name}</Link>
              ) : (
                item.name
              ),
          }
        }),
      )
    }
  }, [location, routeList])
  // console.log('routeConfig:', routeConfig)
  return (
    <BreadcrumbsWrapped>
      <Breadcrumb items={breadcrumbs} />
    </BreadcrumbsWrapped>
  )
}

const BreadcrumbsWrapped = styled.div`
  // position: sticky;
  z-index: 1000;
  display: flex;
  align-items: center;
  top: 48px;
  right: 0;
  height: 45px;
  padding-left: 20px;
  box-sizing: border-box;
  /* background: #fff; */
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  & .ant-breadcrumb-link {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
`
