import React, { useState, createContext, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import styled from 'styled-components'
import { Spin } from 'antd'

import { useLocation, useHistory } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { getMeInfo } from '@/services/config/getConfig'
import Loading from '@/components/Loading'

export const MeContext = createContext(null)

const MeProvider = ({ children }: any) => {
  const [meInfo, setMeInfo] = useState<any>({})
  const [pageError, setPageError] = useState<any>(null)
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const accessToken = params.get('accessToken')
  useEffect(() => {
    if (accessToken) {
      if (
        location.pathname === '/' &&
        (!Cookies.get('accessToken') || !meInfo.isAuthenticated)
      ) {
        setMeInfo(state => ({
          ...state,
          accessToken,
        }))
        Cookies.set('accessToken', accessToken)
      } else {
        setMeInfo(state => ({
          ...state,
          accessToken: Cookies.get('accessToken'),
        }))
      }
    } else {
      if (!Cookies.get('accessToken')) {
        Cookies.set('accessToken', ':(')
      }

      setMeInfo(state => ({
        ...state,
        accessToken: Cookies.get('accessToken'),
      }))
    }
  }, [location, accessToken])

  useEffect(() => {
    if (meInfo.accessToken) {
      if (
        !meInfo.isAuthenticated
        // && !Cookies.get('userInfo')
      ) {
        getMeInfo()
          .then(res => {
            if (res.data && res.code === 0) {
              setMeInfo(state => ({
                ...state,
                isAuthenticated: true,
                userInfo: {
                  ...res.data,
                },
              }))
            }
          })
          .catch(err => {
            setMeInfo(state => ({
              ...state,
              userInfo: null,
              isAuthenticated: false,
            }))
          })
      } else {
        setMeInfo(state => ({
          ...state,
          isAuthenticated: true,
          userInfo: JSON.parse(Cookies.get('userInfo')),
        }))
      }
    }
  }, [meInfo.accessToken])

  useEffect(() => {
    if (meInfo.userInfo) {
      Cookies.set('userInfo', JSON.stringify(meInfo.userInfo))
    }
  }, [meInfo.userInfo])

  useEffect(() => {
    window.eventBus.on('@401', err => {
      console.log('str:', err)
      // junp to 401 error page
      setPageError(err)
      history.push('/error-page')
    })
  }, [])

  if (meInfo.isAuthenticated === undefined) {
    return <Loading />
  }
  return (
    <MeContext.Provider value={{ ...meInfo, pageError, setPageError }}>
      {children}
    </MeContext.Provider>
  )
}

export default MeProvider

export const useMeContext = () => {
  const context = useContext(MeContext)
  if (context === undefined) {
    throw new Error('useMeContext must be used within a MeProvider')
  }
  return context
}
