import React, { createContext, useContext, useEffect, useState } from 'react'
import { ConfigProvider, theme as antdTheme } from 'antd'

const defaultTheme: any = {
  algorithm: antdTheme.defaultAlgorithm,
  token: {
    colorPrimary: '#1677ff',
    borderRadius: 6,
    fontSize: 14,
  },
  components: {
    Button: {
      colorPrimary: '#1677ff',
    },
  },
}

// const darkKnightTheme: any = {
//   algorithm: antdTheme.darkAlgorithm,
//   borderRadius: 6,
//   colorPrimary: '#fff',
//   Button: {
//     colorPrimary: '#333',
//   },
// }

const darkKnightTheme: any = {
  algorithm: antdTheme.darkAlgorithm,
  token: {
    colorPrimary: '#333',
    borderRadius: 6,
    fontSize: 14,
  },
  components: {
    Button: {
      colorPrimary: '#333',
    },
  },
}

const GlobalConfigContext = createContext(null)

const GlobalConfigProvider = ({ children }: any) => {
  const [them, setThem] = useState<any>(defaultTheme)
  const [switchTheme, setSwitchTheme] = useState<boolean>(false)

  useEffect(() => {
    if (setSwitchTheme) {
      setThem(darkKnightTheme)
    } else {
      setThem(defaultTheme)
    }
  }, [switchTheme])

  return (
    <GlobalConfigContext.Provider
      value={{
        setThem: setThem,
        switchTheme: switchTheme,
        setSwitchTheme: setSwitchTheme,
      }}
    >
      <ConfigProvider theme={switchTheme ? darkKnightTheme : defaultTheme}>
        {children}
      </ConfigProvider>
    </GlobalConfigContext.Provider>
  )
}

export default GlobalConfigProvider

export const useGlobalConfigProvider = () => {
  const context = useContext(GlobalConfigContext)
  if (context === undefined) {
    throw new Error('useMeContext must be used within a GlobalConfigProvider')
  }
  return context
}
