import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { fetchGetConfig } from '@/services/config/getConfig'

// thunk函数允许执行异步逻辑, 通常用于发出异步请求。
// createAsyncThunk 创建一个异步action，方法触发的时候会有三种状态：
// pending（进行中）、fulfilled（成功）、rejected（失败）
export const getConfigData = createAsyncThunk('config/getConfig', async () => {
  const res = await fetchGetConfig()
  return res
})

export interface ConfigStateType {
  data: object
  loading: boolean
  error: object
}

const initialState: ConfigStateType = {
  data: {},
  loading: false,
  error: {},
}

// 创建一个 Slice
export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    //可以定义一些同步的方法,如更新state里面的值 add、remove、filter ....
  },
  extraReducers(builder) {
    builder
      .addCase(getConfigData.pending, state => {
        console.log('🚀 ~ 进行中！', state)
        state.loading = true
      })
      .addCase(getConfigData.fulfilled, (state, { payload }) => {
        console.log('🚀 ~ fulfilled', payload)
        state.data = payload?.data
        state.loading = false
      })
      .addCase(getConfigData.rejected, (state, err) => {
        console.log('🚀 ~ rejected', err)
        state.loading = false
      })
  },
})

// reducers
// export const { loadDataEnd } = configSlice.actions

// slice
export default configSlice.reducer
