import React, { useState, useEffect, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EventEmitter from 'eventemitter3'

//Components
import OverrideAntdNotification from '@/components/OverrideAntd/OverrideAntdNotification'
import GlobalConfigProvider from '@/components/AntdConfigProvider'
import SideMenu from '@/components/SideMenu'
import MainContent from '@/components/MainContent'
import PageLayout from '@/components/PageLayout'
import Loading from '@/components/Loading'
//Hooks
// import { useCustomizeRouter } from './routes/useCustomizeRouter'

//Redux toolkit
import { Provider } from 'react-redux'
import store from './store'

// Providers
import GlobalProvider from './providers'

//Css
import './App.css'

if (!window.eventBus) {
  window.eventBus = new EventEmitter()
}

function App() {
  const { t } = useTranslation()

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <Router>
            <GlobalConfigProvider>
              <OverrideAntdNotification>
                <Switch>
                  <Route path="/login">
                    {/* 登录页面，不使用 GlobalProvider */}
                    <PageLayout>
                      <MainContent />
                    </PageLayout>
                  </Route>
                  <Route path="/">
                    {/* 其他页面，使用 GlobalProvider */}
                    <GlobalProvider>
                      <PageLayout>
                        <SideMenu />
                        <MainContent />
                      </PageLayout>
                    </GlobalProvider>
                  </Route>
                </Switch>
              </OverrideAntdNotification>
            </GlobalConfigProvider>
          </Router>
        </Provider>
      </Suspense>
    </>
  )
}

export default App
