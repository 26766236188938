import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import { Switch } from 'antd'

import { IconCollapsed } from '@/components/Icons'

import styled from 'styled-components'

import { useGlobalConfigProvider } from '../AntdConfigProvider'

const Header = () => {
  const batman = Cookies.get('Batman')

  const { setSwitchTheme, switchTheme } = useGlobalConfigProvider()
  const [collapsed, setCollapsed] = useState(
    JSON.parse(localStorage.getItem('_sidebarCollapsed')) || false,
  )
  const handleIconCollapsed = () => {
    setCollapsed(collapsed => !collapsed)
  }

  useEffect(() => {
    if (!batman || batman == '0') {
      setSwitchTheme(false)
    } else {
      setSwitchTheme(true)
    }
  }, [batman])

  useEffect(() => {
    window.eventBus.emit('@toggleMenu', collapsed)
  }, [collapsed])

  const onChange = (checked: boolean) => {
    setSwitchTheme(checked)
    Cookies.set('Batman', checked ? '1' : '0')
  }

  return (
    <HeaderWrapped>
      <header>
        <IconCollapsed onClick={handleIconCollapsed} />
      </header>

      {/* Open dark mode */}
      <div className="dark_knight">
        <span title="Batman help me">Dark Knight:</span>{' '}
        <Switch checked={switchTheme} onChange={onChange} />
      </div>
    </HeaderWrapped>
  )
}

const HeaderWrapped = styled.div`
  // position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  /* background: #fff; */
  padding-left: 20px;
  z-index: 1000;
  justify-content: space-between;
  padding-right: 15px;

  svg {
    cursor: pointer;
  }

  .dark_knight {
    font-size: 14px;
  }
`

export default Header
