import React from 'react'
import { Route } from 'react-router-dom'

function CreateNestedRoutes(props) {
  return (
    <Route
      path={props.path}
      // exact={props.exact}
    >
      <props.component routes={props.routes} />
    </Route>
  )
}
export default CreateNestedRoutes
