import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useMeContext } from '@/providers/meProvider'

const REACT_APP_ENV = process.env.REACT_APP_ENV
let ADMIN_PANNEL_URL = 'https://admin-panel.bossjob.com'
if (REACT_APP_ENV === 'development') {
  ADMIN_PANNEL_URL = 'https://dev.admin-panel.bossjob.com'
}

const Page404 = () => {
  const location = useLocation()
  const PageErrorWrappedRef = useRef(null)
  const context = useMeContext()

  useEffect(() => {
    PageErrorWrappedRef.current.style.zIndex = 20000
  }, [])
  useEffect(() => {
    console.log('context:', context)
    if (context?.pageError?.code == 401) {
      window.location.replace(`${ADMIN_PANNEL_URL}/login`)
      return
    }
  }, [context])
  return (
    <PageErrorWrapped ref={PageErrorWrappedRef}>
      <h1>{context.pageError.code}</h1>
      <p>{context.pageError.message}</p>
    </PageErrorWrapped>
  )
}
const PageErrorWrapped = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  h1 {
    margin-bottom: 20px;
    font-size: 50px;
  }
`
export default Page404
