import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

const Page404 = () => {
  const location = useLocation()
  const page404WrappedRef = useRef(null)
  useEffect(() => {
    page404WrappedRef.current.style.zIndex = 20000
  }, [])
  return (
    <Page404Wrapped ref={page404WrappedRef}>
      <h1>404</h1>
      <p>
        {window.location.origin}
        {location.pathname}
      </p>
    </Page404Wrapped>
  )
}
const Page404Wrapped = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  h1 {
    margin-bottom: 20px;
    font-size: 50px;
  }
`
export default Page404
