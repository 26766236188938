import React, { createContext, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/store'
import { getConfigData } from '@/store/configSlices'

export const configContextImplement = createContext(null)

const ConfigProvider = ({ children }: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const config = useSelector(store => (store as any).configSlice?.data)
  useEffect(() => {
    dispatch(getConfigData())
  }, [])

  return (
    <configContextImplement.Provider value={{ config: config }}>
      {children}
    </configContextImplement.Provider>
  )
}

export default ConfigProvider

export const useConfigContext = () => {
  const context = useContext(configContextImplement)
  if (context === undefined) {
    throw new Error('useConfigContext must be used within a ConfigProvider')
  }
  return context
}
