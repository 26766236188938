import React from 'react'

import MeProvider from './meProvider'
import ConfigProvider from './configProvider'
import LocationProvider from './locationProvider'

const providers = [
  <ConfigProvider key="ConfigProvider" />,
  <MeProvider key="MeProvider" />,
  <LocationProvider key="LocationProvider" />,
]

const GlobalProvider = ({ children }) => {
  return providers.reduceRight((child, item) => {
    return React.cloneElement(item, {}, child)
  }, children)
}

export default GlobalProvider
