import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const Loading = () => {
  return (
    <LoadingWrapped>
      <Spin
        indicator={
          <LoadingOutlined
            spin
            style={{
              fontSize: 54,
            }}
          />
        }
      />
    </LoadingWrapped>
  )
}
const LoadingWrapped = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 20002;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`
export default Loading
