/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Spin, Button, message } from 'antd'
import Cookies from 'js-cookie'
import copy from 'copy-to-clipboard'
import fetchRequest from '@/lib/fetch'
import styled from '@emotion/styled'

const isDev = process.env.REACT_APP_ENV === 'development'

const baseURL = 'https://dev.api.bossjob.com/neptune-admin'

const QuickLogin = () => {
  const queryParams = new URLSearchParams(location.search)
  const account = queryParams.get('account') || 'test+1@gmail.com'
  const password = queryParams.get('password') || 'password'
  const [loginInfo, setLoginInfo] = useState({
    loginStatus: false,
    loginResult: '',
  })

  if (!isDev) {
    return <Redirect to="/notfound" />
  }

  const sampleUrl = `${location.href}?account=test+1@gmail.com&password=password`

  useEffect(() => {
    if (!account) return

    const fetchUserData = () => {
      const curHost = window.location.hostname
      const data = new FormData()
      data.append('username', account)
      data.append('password', password)
      fetchRequest('/admin/login/access-token', {
        method: 'post',
        data,
        baseURL,
      })
        .then(res => {
          const data = JSON.parse(res)
          const accessToken = data?.access_token
          if (accessToken) {
            // 设置安全标记、HTTPOnly属性和过期时间
            // const expirationDate = new Date()
            // expirationDate.setDate(expirationDate.getDate() + 7) // 7天过期时间
            // document.cookie = `accessToken=${accessToken}; domain=${curHost}; expires=${expirationDate.toUTCString()}; path=/;`
            Cookies.set('accessToken', accessToken)
            const loginData = Object.assign({}, loginInfo, {
              loginStatus: true,
              loginResult: '登录成功',
            })
            setLoginInfo(loginData)
          }
        })
        .catch(err => {
          setLoginInfo(
            Object.assign({}, loginInfo, {
              loginStatus: true,
              loginResult: err[1]?.data?.message || '登录异常，请重试',
            }),
          )
        })
    }

    fetchUserData()
  }, [])

  const handleCopyLink = () => {
    if (!copy(sampleUrl)) {
      message.success('复制失败')
      return
    }
    message.success('复制成功')
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <QuickLoginWrap>
      {!account ? (
        <div>
          <p className="title">缺少account，请在浏览器中添加account参数</p>
          <p>
            例如：{sampleUrl}
            <Button
              className="btn-copy"
              type="primary"
              onClick={() => handleCopyLink()}
            >
              复制链接
            </Button>
          </p>
        </div>
      ) : (
        <div>
          <Spin tip="登录中..." spinning={!loginInfo.loginStatus}>
            <span className="login-result">
              {loginInfo.loginStatus ? loginInfo.loginResult : ''}
            </span>
          </Spin>
        </div>
      )}
    </QuickLoginWrap>
  )
}

export default QuickLogin

const QuickLoginWrap = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2378e5;
  .title {
    color: #ff4d4e;
    text-align: center;
  }
  .login-result {
    display: inline-block;
    min-width: 100px;
    color: #2378e5;
  }
  .btn-copy {
    margin-left: 4px;
  }
`
