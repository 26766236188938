// index.ts 文件

import { configureStore } from '@reduxjs/toolkit'
import configSlice from './configSlices'
import { useDispatch } from 'react-redux'

// configureStore创建一个redux数据
const store = configureStore({
  // 合并多个Slice
  reducer: {
    configSlice,
  },
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export default store
