import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { fetchConfigCountry } from '@/services/config/getConfig'

export const locationContextImplement = createContext(null)

const LocationProvider = ({ children }: any) => {
  const [location, setLocation] = useState<any>(null)

  useEffect(() => {
    fetchConfigCountry()
      .then(({ code, message, data }) => {
        if (code == 0 && message == 'Success') {
          setLocation(data)
        }
      })
      .catch(() => {
        //
      })
  }, [])

  return (
    <locationContextImplement.Provider value={{ location: location }}>
      {children}
    </locationContextImplement.Provider>
  )
}

export default LocationProvider

export const useLocationContext = () => {
  const context = useContext(locationContextImplement)
  if (context === undefined) {
    throw new Error('useConfigContext must be used within a LocationProvider')
  }
  return context
}
