import React from 'react'
// import { useMeContext } from '@/providers/meProvider'
// import { useLocation, useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { useGlobalConfigProvider } from '../AntdConfigProvider'

const PageLayout = ({ children }) => {
  // const location = useLocation()
  // const history = useHistory()

  // const { pageError, setPageError } = useMeContext()
  const { switchTheme } = useGlobalConfigProvider()

  return (
    <PageLayoutWrapped $primary={switchTheme}>
      <main>{children}</main>
    </PageLayoutWrapped>
  )
}

const PageLayoutWrapped = styled.div<{ $primary?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${(props: any) =>
    props.$primary ? 'rgba(0, 0, 0, 0.8)' : 'white'};
  main {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
  }
`

export default PageLayout
