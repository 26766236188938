import fetchRequest from '@/lib/fetch'

const fetchGetConfig = () => {
  return fetchRequest('/config', {
    method: 'GET',
  })
}

const getMeInfo = () => {
  return fetchRequest('/admin/users/me', {
    method: 'GET',
  })
}

const fetchConfigCountry = () => {
  return fetchRequest('/config/country', {
    method: 'GET',
  })
}

export { fetchGetConfig, getMeInfo, fetchConfigCountry }
