import React, { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Spin } from 'antd'
// import { LoadingOutlined } from '@ant-design/icons'

import { useMeContext } from '@/providers/meProvider'

const Home = () => {
  const history = useHistory()

  const { isAuthenticated } = useMeContext()
  useLayoutEffect(() => {
    history.push('/customer-high-seas')
  }, [isAuthenticated])
  return null
}
// const HomeWrapped = styled.div`
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   z-index: 20002;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: #fff;
// `
export default Home
