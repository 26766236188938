import React, { useState, memo, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import useRouteConfig from '@/routes'
import { useMeContext } from '@/providers/meProvider'
import { IconSiteLogo } from '@/components/Icons'
const handleMatchMenuList = (data, value) => {
  const result = []
  data.forEach(({ ...item }) => {
    if (item.permissions.includes(value) && item.isMenu) {
      if (item.routes && Array.isArray(item.routes)) {
        item.routes = handleMatchMenuList(item.routes, value)
        if (!item.routes.length) {
          delete item.routes
        }
      }
      result.push(item)
    }
  })
  return result
}

const createSideMenuList = data => {
  return data.map(({ ...item }) => {
    item.children = []
    item.label = item.name
    item.key = item.name
    if (item.routes && Array.isArray(item.routes)) {
      item.children = createSideMenuList(item.routes)
    }
    return {
      label: item.label,
      key: item.path,
      icon: item.icon,
      // disabled: item.path.indexOf('/dashboard') !== -1,
      children: item.children.length ? item.children : null,
    }
  })
}

function SideMenu() {
  const history = useHistory()
  const location = useLocation()
  const routeConfig = useRouteConfig()
  const me = useMeContext()
  const [menuList, setMenuList] = useState([])
  const sideMenuWrappedRef = useRef(null)

  const menuListFirstLevel = [...menuList].map(item => item.key)
  const [openKeys, setOpenKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [collapsed, setCollapsed] = useState(false)

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (latestOpenKey && menuListFirstLevel.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  useEffect(() => {
    window.eventBus.on('@toggleMenu', state => {
      setCollapsed(state)
      localStorage.setItem('_sidebarCollapsed', JSON.stringify(state))
    })
  }, [])

  useEffect(() => {
    const { pathname } = location
    const splitPathName = pathname.split('/')
    if (!collapsed) {
      setOpenKeys(['/' + splitPathName[1]])
    }
    setSelectedKeys(['/' + splitPathName[1] + '/' + splitPathName[2]])
  }, [location, collapsed])

  useEffect(() => {
    if (me?.userInfo?.role?.value) {
      setMenuList(
        createSideMenuList(
          handleMatchMenuList([...routeConfig], me?.userInfo?.role?.value),
        ),
      )
    }
  }, [me?.userInfo?.role?.value])

  return (
    <SideMenuWrapped
      className={!collapsed ? '' : 'narrow'}
      ref={sideMenuWrappedRef}
    >
      <div className="site-logo">
        <IconSiteLogo />
      </div>
      <Menu
        mode="inline"
        items={menuList}
        inlineCollapsed={collapsed}
        defaultOpenKeys={openKeys}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        onClick={e => {
          history.push(e.key)
        }}
      />
    </SideMenuWrapped>
  )
}

const SideMenuWrapped = styled.div`
  z-index: 999;
  position: relative;
  width: 256px;
  overflow: hidden;
  overflow-y: auto;
  /* background: #fff; */
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  &.narrow {
    width: 80px;
    .site-logo {
      svg {
        display: none;
      }
    }
  }
  .site-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    resize: both;

    img,
    svg {
      max-width: 100%;
      height: auto;
      /* padding: 15px 0; */
    }
    svg:first-child {
      padding: 16px 0 6px;
    }
    svg:last-child {
      padding: 0 0 30px;
    }
  }

  & .ant-menu-submenu {
    svg {
      margin-right: 10px;
    }
  }
  & .ant-menu-submenu-selected {
    svg {
      path {
        fill: #1677ff;
      }
    }
  }

  & .ant-menu-root.ant-menu-inline,
  &
    :where(
      .css-dev-only-do-not-override-1wch7tx
    ).ant-menu-light.ant-menu-root.ant-menu-vertical,
  &
    :where(
      .css-dev-only-do-not-override-sorraf
    ).ant-menu-light.ant-menu-root.ant-menu-vertical,
  & :where(.css-dev-only-do-not-override-sorraf).ant-menu {
    border: none;
  }
  & .ant-menu-root.ant-menu-inline,
  &
    :where(.css-dev-only-do-not-override-sorraf).ant-menu-light.ant-menu-inline
    .ant-menu-sub.ant-menu-inline {
    background: none;
  }

  & .ant-menu,
  .ant-menu-root.ant-menu-inline {
    .ant-menu-submenu-open {
      svg {
        path {
          fill: #1677ff;
        }
      }
      .ant-menu-submenu-title {
        color: #1677ff;
      }
    }
  }
  & .ant-menu-inline-collapsed {
    .ant-menu-title-content {
      display: none;
    }
  }
`
export default memo(SideMenu)
