import React, { useEffect, useState, createContext } from 'react'
import styled from 'styled-components'
import { Switch, Route, useLocation } from 'react-router-dom'

import useRouteConfig from '@/routes'
// import ProtectedRoutes from '@/components/ProtectedRoutes'
import CreateNestedRoutes from '@/components/NestedRoutes'
import Breadcrumbs from '@/components/Breadcrumbs'
import Header from '@/components/Header'
import Home from '@/pages/home'
import QuickLogin from '@/pages/quickLogin/quickLogin'
import Page404 from '@/pages/404'
import ErrorPage from '@/pages/error'
import { useMeContext } from '@/providers/meProvider'
import usePrevious from '@/lib/hooks/usePrevious'

const handleMatchRouteList = (data, value) => {
  const result = []
  data.forEach(({ ...item }) => {
    if (item.permissions.includes(value)) {
      if (item.routes && Array.isArray(item.routes)) {
        item.routes = handleMatchRouteList(item.routes, value)
        if (!item.routes.length) {
          delete item.routes
        }
      }
      result.push(item)
    }
  })
  return result
}

export const RouteChangeContext = createContext(undefined)

const MainContent = () => {
  const routeConfig = useRouteConfig()
  const me = useMeContext()
  const location = useLocation()
  const prevLocation = usePrevious(location)
  const [routeList, setRouteList] = useState([])

  const renderRoutes = () => {
    return routeList.map(route => (
      //<ProtectedRoutes key={route.path} path={route.path}>
      <CreateNestedRoutes key={route.path} {...route} />
      //</ProtectedRoutes>
    ))
  }
  useEffect(() => {
    if (me?.userInfo?.role?.value) {
      setRouteList(
        handleMatchRouteList([...routeConfig], me?.userInfo?.role?.value),
      )
    }
  }, [me?.userInfo?.role?.value])

  return (
    <MainContentWrapped>
      <Header />
      <Breadcrumbs />
      <div className="main-content" id="main-content">
        <RouteChangeContext.Provider
          value={{
            prevLocation,
          }}
        >
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/login">
              <QuickLogin />
            </Route>
            {renderRoutes()}
            <Route path="/error-page">
              <ErrorPage />
            </Route>
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>
        </RouteChangeContext.Provider>
      </div>
    </MainContentWrapped>
  )
}

const MainContentWrapped = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
  .main-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* min-width: 1200px; */
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    /* background: #fff; */
  }
`
export default MainContent
