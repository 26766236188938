import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './config.sentry'
//I18n
import i18n from '../i18n'
// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
// root.render(<App />)
i18n.then(() => {
  ReactDOM.render(<App />, document.getElementById('root') as HTMLElement)
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})
