/* eslint-disable @typescript-eslint/explicit-function-return-type */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// import enLanguage from 'src/localesNeptune/id-ID/id-ID.json'
// import enErrorCode from './errorcode/en-US/errorcode.json'

// import zhLanguage from 'src/localesNeptune/zh-CN/zh-CN.json'
// import zhErrorCode from './errorcode/zh-CN/errorcode.json'

// import idLanguage from 'src/localesNeptune/id-ID/id-ID.json'
// import idErrorCode from './errorcode/id-ID/errorcode.json'

// import jpLanguage from 'src/localesNeptune/ja-JP/ja-JP.json'
// import jpErrorCode from './errorcode/ja-JP/errorcode.json'

// import twLanguage from 'src/localesNeptune/zh-TW/zh-TW.json'
// import twErrorCode from './errorcode/zh-TW/errorcode.json'

// export const resources = {
//   'en-US': { translation: { ...enLanguage, ...enErrorCode } },
//   'zh-CN': { translation: { ...zhLanguage, ...zhErrorCode } },
//   id: { translation: { ...idLanguage, ...idErrorCode } },
//   ja: { translation: { ...jpLanguage, ...jpErrorCode } },
//   'ja-JP': { translation: { ...jpLanguage, ...jpErrorCode } },
//   'zh-TW': { translation: { ...twLanguage, ...twErrorCode } },
//   'zh-HK': { translation: { ...twLanguage, ...twErrorCode } },
// }

export const getResource = async () => {
  const languages = await fetch(
    'https://distributions.crowdin.net/ab572c0ae5e80e582ac1f49ef5z/manifest.json',
  )
    .then(res => {
      return res.json()
    })
    .catch(error => {
      console.log({ error })
    })
  const resources = await Promise.all(
    languages?.languages?.map?.(async key => {
      const contentPaths = languages.content[key]
      const content = await Promise.all(
        contentPaths.map(async contentPath => {
          const contentUrl = `https://distributions.crowdin.net/ab572c0ae5e80e582ac1f49ef5z${contentPath}?timestamp=${languages.timestamp}`
          const content = await fetch(contentUrl).then(res => res.json())
          return content
        }),
      ).then(result => result.reduce((a, b) => ({ ...a, ...b })))
      // .then(result => result.data)
      console.log({ [key]: { translation: content } })
      return { [key]: { translation: content } }
    }),
  ).then(arr => arr.reduce((a, b) => ({ ...a, ...b }), {}))
  console.log({ resources })
  return resources
}
async function init() {
  const resources = await getResource()

  i18n
    .use(LanguageDetector) //根据用户浏览器 语言进行配置
    .use(initReactI18next)

  return await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    // 初始化 i18next
    // 配置参数的文档: https://www.i18next.com/overview/configuration-options
    .init({
      resources,
      fallbackLng: 'zh', // 默认当前环境的语言
      debug: false, // 是否启用调试模式
      interpolation: { escapeValue: false },
    })
}

export default init()
// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   // 初始化 i18next
//   // 配置参数的文档: https://www.i18next.com/overview/configuration-options
//   .init({
//     resources,
//     fallbackLng: 'zh', // 默认当前环境的语言
//     debug: false, // 是否启用调试模式
//     interpolation: { escapeValue: false },
//   })

// export default i18n
