import React, { useMemo, lazy } from 'react'
import { useTranslation } from 'react-i18next'

// const Home = lazy(() => import('@/pages/home'))
// const Dashboard = lazy(() => import('@/pages/dashboard'))
// const Analysis = lazy(() => import('@/pages/dashboard/analysis'))
// const AnalysisAdd = lazy(() => import('@/pages/dashboard/analysis/add'))

// customer high seas
const CustomerHighSeas = lazy(() => import('@/pages/customerHighSeas'))

const CustomerHighSeasIndex = lazy(
  () => import('@/pages/customerHighSeas/list'),
)
const CustomerHighSeasAdd = lazy(
  () => import('@/pages/customerHighSeas/list/add'),
)
const CustomerHighSeasDetail = lazy(
  () => import('@/pages/customerHighSeas/list/detail'),
)
const CustomerHighSeasEdit = lazy(
  () => import('@/pages/customerHighSeas/list/edit'),
)

const MyCustomer = lazy(() => import('@/pages/myCustomer'))
const MyCustomerIndex = lazy(() => import('@/pages/myCustomer/list'))
const MyCustomerAdd = lazy(() => import('@/pages/myCustomer/list/add'))
const MyCustomerDetail = lazy(() => import('@/pages/myCustomer/list/detail'))
const MyCustomerEdit = lazy(() => import('@/pages/customerHighSeas/list/edit'))

// const MyCustomerList = lazy(() => import('@/pages/myCustomer/list'))
const CustomerAllocation = lazy(() => import('@/pages/customerAllocation'))
const CustomerAllocationAssignedIndex = lazy(
  () => import('@/pages/customerAllocation/assignedCustomer/list'),
)
const CustomerAllocationAssignedAdd = lazy(
  () => import('@/pages/customerAllocation/assignedCustomer/list/add'),
)
const CustomerAllocationAssignedDetail = lazy(
  () => import('@/pages/customerAllocation/assignedCustomer/list/detail'),
)
const CustomerAllocationAssignedEdit = lazy(
  () => import('@/pages/customerAllocation/assignedCustomer/list/edit'),
)
const CustomerAllocationUnAssignedIndex = lazy(
  () => import('@/pages/customerAllocation/unAssignedCustomer/list'),
)
const CustomerAllocationUnAssignedAdd = lazy(
  () => import('@/pages/customerAllocation/unAssignedCustomer/list/add'),
)
const CustomerAllocationUnAssignedDetail = lazy(
  () => import('@/pages/customerAllocation/unAssignedCustomer/list/detail'),
)
const CustomerAllocationUnAssignedEdit = lazy(
  () => import('@/pages/customerAllocation/unAssignedCustomer/list/edit'),
)

import {
  IconDashboard,
  IConCustomerHighSeas,
  IconMyCustomers,
  IconCustomerAllocation,
} from '@/components/Icons'

const useRouteConfig = () => {
  const routeConfig = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   isMenu: false,
    //   component: Home,
    //   permissions: ['admin', 'user'],
    // },
    // {
    //   path: '/dashboard',
    //   name: 'Dashboard',
    //   component: Dashboard,
    //   permissions: ['Admin', 'Ordinary'],
    //   isMenu: true,
    //   icon: <IconDashboard />,
    //   routes: [
    //     {
    //       path: '/dashboard/analysis',
    //       name: 'Analysis',
    //       component: Analysis,
    //       permissions: ['Admin', 'Ordinary'],
    //       isMenu: true,

    //       routes: [
    //         {
    //           path: '/dashboard/analysis/add',
    //           name: 'Add',
    //           component: AnalysisAdd,
    //           permissions: ['Admin', 'Ordinary'],
    //           isMenu: false,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      path: '/customer-high-seas',
      name: 'Customer High Seas',
      component: CustomerHighSeas,
      permissions: ['Admin', 'Ordinary'],
      isMenu: true,
      icon: <IConCustomerHighSeas />,
      routes: [
        {
          path: '/customer-high-seas/customer-lists',
          name: 'Customer HighSeas Lists',
          component: CustomerHighSeasIndex,
          permissions: ['Admin', 'Ordinary'],
          isMenu: true,
          routes: [
            {
              path: '/customer-high-seas/customer-lists/detail',
              name: 'Detail',
              component: CustomerHighSeasDetail,
              permissions: ['Admin', 'Ordinary'],
              isMenu: false,
            },
            {
              path: '/customer-high-seas/customer-lists/add',
              name: 'Add',
              component: CustomerHighSeasAdd,
              permissions: ['Admin', 'Ordinary'],
              isMenu: false,
            },
            {
              path: '/customer-high-seas/customer-lists/edit',
              name: 'Edit',
              component: CustomerHighSeasEdit,
              permissions: ['Admin', 'Ordinary'],
              isMenu: false,
            },
          ],
        },
      ],
    },
    {
      path: '/my-customer',
      name: 'My Customer',
      component: MyCustomer,
      permissions: ['Admin', 'Ordinary'],
      isMenu: true,

      icon: <IconMyCustomers />,
      routes: [
        {
          path: '/my-customer/customer-lists',
          name: 'My Customer Lists',
          component: MyCustomerIndex,
          permissions: ['Admin', 'Ordinary'],
          isMenu: true,
          routes: [
            {
              path: '/my-customer/customer-lists/detail',
              name: 'Detail',
              component: MyCustomerDetail,
              permissions: ['Admin', 'Ordinary'],
              isMenu: false,
            },
            {
              path: '/my-customer/customer-lists/add',
              name: 'Add',
              component: MyCustomerAdd,
              permissions: ['Admin', 'Ordinary'],
              isMenu: false,
            },
            {
              path: '/my-customer/customer-lists/edit',
              name: 'Edit',
              component: MyCustomerEdit,
              permissions: ['Admin', 'Ordinary'],
              isMenu: false,
            },
          ],
        },
      ],
    },
    {
      path: '/customer-allocation',
      name: 'Customer Allocation',
      component: CustomerAllocation,
      permissions: ['Admin'],
      isMenu: true,
      icon: <IconCustomerAllocation />,
      routes: [
        {
          path: '/customer-allocation/assigned-customer',
          name: 'Assigned Customer',
          component: CustomerAllocationAssignedIndex,
          permissions: ['Admin'],
          isMenu: true,
          routes: [
            {
              path: '/customer-allocation/assigned-customer/detail',
              name: 'Detail',
              component: CustomerAllocationAssignedDetail,
              permissions: ['Admin'],
              isMenu: false,
            },
            {
              path: '/customer-allocation/assigned-customer/add',
              name: 'Add',
              component: CustomerAllocationAssignedAdd,
              permissions: ['Admin'],
              isMenu: false,
            },
            {
              path: '/customer-allocation/assigned-customer/edit',
              name: 'Edit',
              component: CustomerAllocationAssignedEdit,
              permissions: ['Admin'],
              isMenu: false,
            },
          ],
        },
        {
          path: '/customer-allocation/unassigned-customer',
          name: 'Unassigned Customer',
          component: CustomerAllocationUnAssignedIndex,
          permissions: ['Admin'],
          isMenu: true,
          routes: [
            {
              path: '/customer-allocation/unassigned-customer/detail',
              name: 'Detail',
              component: CustomerAllocationUnAssignedDetail,
              permissions: ['Admin'],
              isMenu: false,
            },
            {
              path: '/customer-allocation/unassigned-customer/add',
              name: 'Add',
              component: CustomerAllocationUnAssignedAdd,
              permissions: ['Admin'],
              isMenu: false,
            },
            {
              path: '/customer-allocation/unassigned-customer/edit',
              name: 'Edit',
              component: CustomerAllocationUnAssignedEdit,
              permissions: ['Admin'],
              isMenu: false,
            },
          ],
        },
      ],
    },
  ]
  return useMemo(() => routeConfig, [])
}
export default useRouteConfig
