import React from 'react'
import Cookies from 'js-cookie'
import { notification } from 'antd'

const serverConfig: any = {
  baseURL: process.env.REACT_APP_REQUEST_API,
  useTokenAuthorization: true, // 是否开启 token 认证
  timeout: 1000 * 60 * 5,
  options: {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {},
  },
}

// 封装Fetch请求函数
async function fetchRequest(api: string, config: { [key: string]: any }) {
  // 如果开启 token 认证
  let url
  if (!config.baseURL) {
    url = serverConfig.baseURL + api
  } else {
    url = config.baseURL + api
  }

  const options = {
    ...serverConfig.options,
    ...config,
  }

  if (serverConfig.useTokenAuthorization) {
    options.headers['Authorization'] = `Bearer ${Cookies.get('accessToken')}` // 请求头携带 token
  }

  // 检查是否使用 FormData
  const isFormData = options.data instanceof FormData

  // 设置请求头和请求体
  if (!isFormData) {
    options.headers['Content-Type'] = 'application/json; charset=UTF-8' // 默认类型
    if (options.data) {
      options.body = JSON.stringify(options.data)
    }
  } else {
    // 如果是 FormData，则不需要设置 Content-Type，让浏览器自行处理
    options.body = options.data
  }

  try {
    const response = await fetch(url, options)

    if (!response.ok) {
      throw response
    }

    const whiteList = ['/export-competitors-data-to-download', '/login']
    const isResponseText = whiteList.some(item => url.indexOf(item) !== -1)
    const data = await (isResponseText ? response.text() : response.json())
    // 处理自己的业务逻辑，比如判断 token 是否过期等等
    // 代码块
    return data
  } catch (response) {
    let error: any
    try {
      const errors = await response.json()
      error = errors
    } catch (errorMsg) {
      error = response
    }

    let message = ''

    switch (error && error.code) {
      case 302:
        message = '接口重定向了！'
        break
      case 400:
        message = '参数不正确！'
        break
      case 401:
        message = '您未登录，或者登录已经超时，请先登录！'
        window.eventBus.emit('@401', error)
        break
      case 403:
        message = '您没有权限操作！'
        break
      case 404:
        message = `请求地址出错: ${error.config.url}`
        break
      case 408:
        message = '请求超时！'
        break
      case 409:
        message = '系统已存在相同数据！'
        break
      case 500:
        message = '服务器内部错误！'
        break
      case 501:
        message = '服务未实现！'
        break
      case 502:
        message = '网关错误！'
        break
      case 503:
        message = '服务不可用！'
        break
      case 504:
        message = '服务暂时无法访问，请稍后再试！'
        break
      case 505:
        message = 'HTTP 版本不受支持！'
        break
      default:
        message = '异常问题，请联系管理员！'
        break
    }
    notification.error({
      placement: 'bottomLeft',
      message: 'Error',
      description: <div>接口出现错误</div>,
    })
    return Promise.reject({ response: error, message })
  }
}

export default fetchRequest
